import React from "react";

const Logo = (props) => {
  return (
    <img
      src="/images/Mobiloitte_blue_logo.png"
      onDragStart={(e) => e.preventDefault()}
      onContextMenu={(e) => e.preventDefault()}
      alt="Logo"
      {...props}
    />
  );
};

export default Logo;
