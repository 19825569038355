import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Grid,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import Logo from "../../component/Logo";
import Scroll from "react-scroll";
import { AuthContext } from "src/context/Auth";
const ScrollLink = Scroll.Link;

const useStyles = makeStyles((theme) => ({
  appBar: {
    color: "rgba(255,255,255,1)",
    top: "0px",
    border: "none",
    left: "0",
  },
  toolbar: {
    display: "flex",
    padding: "2px 0px 2px 0px",
    justifyContent: "space-between",
    height: "100%",
    "& .active": {
      color: "#a390e1",
    },
    "& nav ul": {
      alignItems: "center !important",
    },
    "& .hovertext": {
      "& :active": {
        color: "#a390e1",
      },
    },
    "@media (max-width: 900px)": {
      paddingLeft: "75px",
      paddingRight: "20px",
      height: "100%",
    },
  },
  logoDrawer: {
    paddingLeft: "10px",
    width: "140px",
    marginBottom: "30px",
  },
  drawerContainer: {
    padding: "20px 0px ",
    height: "100%",
    background: "#000",
    width: "260px",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    "@media(max-width:1219px)": {
      padding: "0px 0px",
    },
  },
  drawericon: {
    color: "#000",
    position: "absolute",
    top: "10px",
    right: "-10px",
    fontSize: "25px",
  },
  containerHeight: {
    height: "100%",
    padding: "0px !important",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "10px 0px",
  },
  typosec: {
    fontFamily: "'Sora', sans-serif",
  },
  containerbox: {
    position: "fixed",
    top: "0",
    zIndex: "999",
    background: "#190e49",
    width: "100%",
    backdropFilter: "blur(12.5px)",
  },
}));

export default function Header() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const auth = useContext(AuthContext);
  const {
    appBar,
    toolbar,
    drawerContainer,
    drawericon,
    containerHeight,
    mainHeader,
  } = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });
  const { mobileView, drawerOpen } = state;
  const tokenCheck = window.sessionStorage.getItem("token");

  let isActive = window.location.search;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1220
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const confirmationHandler = () => {
    history.push("/login");
    window.sessionStorage.removeItem("token");
    window.sessionStorage.removeItem("temp_user_token");

    window.localStorage.removeItem("GBPAmount");
    window.localStorage.removeItem("CoinName");
    auth.userLogIn(false, null);
  };

  const displayDesktop = () => {
    return (
      <Container maxWidth="lg" className="p-0" style={{ padding: "0px" }}>
        <Toolbar className={toolbar}>
          {femmecubatorLogo}
          {window.location.pathname !== "/register" && (
            <Grid
              container
              item
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              style={{ paddingLeft: "0px" }}
              className="activeClass"
            >
              <nav>
                <ul className="navigation">
                  <ScrollLink
                    onClick={() => history.push("/")}
                    className="hovertext"
                    smooth={true}
                    duration={500}
                    to="home"
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      variant="h6"
                      paragraph
                      className={`${classes.typosec} hovertext`}
                    >
                      Home
                    </Typography>
                  </ScrollLink>
                  <ScrollLink
                    onClick={() => history.push("/?id=icocrypto")}
                    className={`hovertext ${
                      isActive === "?id=icocrypto" && "active"
                    }`}
                    smooth={true}
                    duration={500}
                    to="icocrypto"
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      variant="h6"
                      paragraph
                      className={`${classes.typosec} hovertext ${
                        isActive === "?id=icocrypto" && "active"
                      }`}
                    >
                      About
                    </Typography>
                  </ScrollLink>
                  <ScrollLink
                    onClick={() => history.push("/?id=tokenoimics")}
                    className={`hovertext ${
                      isActive === "?id=tokenoimics" && "active"
                    }`}
                    smooth={true}
                    duration={500}
                    to="tokenoimics"
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      variant="h6"
                      paragraph
                      className={`${classes.typosec} hovertext ${
                        isActive === "?id=tokenoimics" && "active"
                      }`}
                    >
                      Tokenomics
                    </Typography>
                  </ScrollLink>
                  <ScrollLink
                    onClick={() => history.push("/?id=roadmap")}
                    className="hovertext"
                    smooth={true}
                    duration={500}
                    to="roadmap"
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      variant="h6"
                      paragraph
                      className={`${classes.typosec} hovertext ${
                        isActive === "?id=roadmap" && "active"
                      }`}
                    >
                      Roadmap
                    </Typography>
                  </ScrollLink>

                  <ScrollLink
                    onClick={() => history.push("/contactus")}
                    className="hovertext"
                    smooth={true}
                    duration={500}
                    to="contactus"
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      variant="h6"
                      paragraph
                      className={`${classes.typosec} hovertext ${
                        window.location.pathname === "/contactus" && "active"
                      }`}
                    >
                      Contact
                    </Typography>
                  </ScrollLink>

                  {tokenCheck ? (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        paragraph
                        className={`${classes.typosec} hovertext`}
                        onClick={() => history.push("/dashboard")}
                      >
                        Dashboard
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        paragraph
                        className={`${classes.typosec} hovertext`}
                        onClick={() => history.push("/login")}
                      >
                        Login
                      </Button>
                    </>
                  )}
                </ul>
              </nav>
            </Grid>
          )}
        </Toolbar>
      </Container>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar className={mainHeader}>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>
            {getDrawerChoices({ handleDrawerClose })}
          </div>
        </Drawer>

        <div>{femmecubatorLogo}</div>
        {window.location.pathname !== "/register" && (
          <Grid container>
            <Grid item xs={10}></Grid>
            <Grid item xs={2}>
              <IconButton
                className={drawericon}
                {...{
                  edge: "start",
                  color: "inherit",
                  "aria-label": "menu",
                  "aria-haspopup": "true",
                  onClick: handleDrawerOpen,
                }}
              >
                <MenuIcon
                  width="60px"
                  height="60px"
                  style={{ color: "#FFF", fontSize: "35px" }}
                />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Toolbar>
    );
  };

  const getDrawerChoices = ({ handleDrawerClose }) => {
    return (
      <>
        <nav>
          <label clasnames="logo">
            <Logo
              style={{
                width: "100%",
                maxWidth: "150px",
                marginTop: "16px",
                marginLeft: "18px",
              }}
            />
          </label>
          <ul className="navigation">
            <ScrollLink
              onClick={() => {
                history.push("/");
                handleDrawerClose();
              }}
              className={`hovertext ${isActive === "?home" && "active"}`}
              smooth={true}
              duration={500}
              to="home"
              style={{ cursor: "pointer", paddingBottom: "5px" }}
            >
              <Typography variant="h6" paragraph className="hovertext">
                Home
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => {
                history.push("/?id=about");
                handleDrawerClose();
              }}
              className={`hovertext ${isActive === "?id=about" && "active"}`}
              smooth={true}
              duration={500}
              to="about"
              style={{ cursor: "pointer", paddingBottom: "5px" }}
            >
              <Typography variant="h6" paragraph className="hovertext">
                About
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => {
                history.push("/?id=tokenoimics");
                handleDrawerClose();
              }}
              className={`hovertext ${
                isActive === "?id=tokenoimics" && "active"
              }`}
              smooth={true}
              duration={500}
              to="tokenoimics"
              style={{ cursor: "pointer", paddingBottom: "5px" }}
            >
              <Typography
                variant="h6"
                paragraph
                className={`${classes.typosec} hovertext`}
              >
                Tokenomics
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => {
                history.push("/?id=roadmap");
                handleDrawerClose();
              }}
              className="hovertext"
              smooth={true}
              duration={500}
              to="roadmap"
              style={{ cursor: "pointer", paddingBottom: "5px" }}
            >
              <Typography
                variant="h6"
                paragraph
                className={`${classes.typosec} hovertext`}
              >
                Roadmap
              </Typography>
            </ScrollLink>
            <ScrollLink
              onClick={() => history.push("/contactus")}
              className="hovertext"
              smooth={true}
              duration={500}
              // to="roadmap"
              style={{ cursor: "pointer", paddingBottom: "5px" }}
            >
              <Typography variant="h6" paragraph className="hovertext">
                Contact
              </Typography>
            </ScrollLink>
            {tokenCheck && (
              <ScrollLink
                onClick={() => setOpen(true)}
                className="hovertext"
                smooth={true}
                duration={500}
                style={{ cursor: "pointer" }}
              >
                <Typography
                  variant="h6"
                  paragraph
                  className={`${classes.typosec} hovertext`}
                >
                  Logout
                </Typography>
              </ScrollLink>
            )}
            {tokenCheck ? (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  paragraph
                  className={`${classes.typosec} hovertext`}
                  onClick={() => history.push("/dashboard")}
                >
                  Dashboard
                </Button>
              </>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  paragraph
                  className={`${classes.typosec} hovertext`}
                  onClick={() => history.push("/login")}
                >
                  Login
                </Button>
              </>
            )}

            {open && (
              <ConfirmationDialog
                open={open}
                handleClose={() => setOpen(false)}
                title={"Logout"}
                desc={"Do you want to Logout"}
                style={{ color: "#fff" }}
                confirmationHandler={confirmationHandler}
              />
            )}
          </ul>
        </nav>
      </>
    );
  };

  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo
          className="logoImg"
          style={{ width: "100%", maxWidth: "240px" }}
        />
      </Link>
    </Box>
  );

  return (
    <>
      <Box className={classes.containerbox}>
        <Container>
          <AppBar
            className={appBar}
            position={
              history.location.pathname !== "/" ? "relative" : "relative"
            }
            elevation={0}
            style={{ background: "none" }}
          >
            <Container
              maxWidth={history.location.pathname !== "/" ? "lg" : "fixed"}
              className={containerHeight}
            >
              {mobileView ? displayMobile() : displayDesktop()}
            </Container>
          </AppBar>
        </Container>
      </Box>
    </>
  );
}
